:root {
    --color-white-pure: #fff;
    --color-white: #eee;
    --color-black-pure: #000;
    --color-black: #222;
    --color-gray-dark: #444;
    --color-gray-light: #cbcbcb;
    --color-blue-primary: #30bcec;
    --color-red: #d72020;
}

* {
    box-sizing: border-box;
}