.pb-seminar-wrapper {
  text-align: center;
}

.pb-page-container {
  max-width: 768px;
  margin: 0 auto;
}

.pb-seminar-wrapper h1 {
  color: #0e4970;
}

.pb-seminar-poster {
  max-width: 100%;
}

.pb-seminar-button {
  display: block;
  max-width: 200px;
  margin: 20px auto;
  background-color: #2fbbec;
  border-radius: 8px;
  padding: 10px 12px;
  font-family: "Poppins";
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  text-decoration: none;
}

.pb-seminar-button:hover{
  background-color: #3fcbfc;
}

.pb-seminar-footer-wrapper {
  background-color: #0e4970;
  padding: 20px;
  color: #fff;
  text-align: left;
}

.pb-seminar-footer-wrapper h3 {
  font-weight: normal;
}

.pb-seminar-footer-wrapper h4 {
  margin: 10px 0 5px;
}

.pb-seminar-footer-wrapper p {
  margin: 0;
}

.pb-sosmed-icon-container {}

.pb-sosmed-icon-container img {
  max-width: 25px;
  margin: 0;
  margin-right: 30px;
}

.pb-seminar-footer-image {
  max-width: 200px;
}

.pb-footer-link {
  display: inline-block;
  color: #fff;
  text-decoration: none;
}